import React from "react"
import { Container, Button, Col, Media, Image, Row } from "react-bootstrap"
import "./styles/how-it-works.styles.scss"
import { StaticImage } from "gatsby-plugin-image"
import Footer from "../components/Layout/Footer"
import NavBarLight from "../components/Layout/navBar/NavBarLight"

const HowItWorks = () => (
  <>
    <NavBarLight />
    <Container fluid className="home-container how-it-works p-0  ">
      <Container fluid className="py-5 py-md-0 bg-container">
        <Container
          fluid="xl"
          className="py-xl-6 d-flex flex-column text-center "
        >
          <h1 className="h1-header" id="top">
            {" "}
            How it works{" "}
          </h1>{" "}
          <Row>
            <Col md={10} className="mx-auto">
              <p>
                Pave Credit makes borrowing easy and fast when you need it
                most.No more applying for multiple loans.If you’ re approved for
                a Pave Credit line of credit, you can draw money from the
                available credit shown in your Pave account and transfer it to
                your bank account.Here’ s how it works.{" "}
              </p>{" "}
              <h3 className="h3-sub-header"> Apply </h3>{" "}
              <p>
                {" "}
                To qualify for a Pave Credit line of credit you will need:{" "}
              </p>{" "}
            </Col>{" "}
          </Row>{" "}
          <Row className="py-3 pb-4">
            <Col md={4} className="mx-auto">
              <p>
                <div className="circle mx-auto"> 1 </div>{" "}
              </p>{" "}
              <p className="description px-5">
                A verifiable source of income that is direct deposited into your
                checking account{" "}
              </p>{" "}
            </Col>{" "}
            <Col md={4} className="mx-auto middle-div">
              <p>
                <div className="circle mx-auto"> 2 </div>{" "}
              </p>{" "}
              <p className="description px-5">
                An open checking account that meets our minimum - opened
                duration requirement{" "}
              </p>{" "}
            </Col>{" "}
            <Col md={4} className="mx-auto">
              <p>
                <div className="circle mx-auto"> 3 </div>{" "}
              </p>{" "}
              <p className="description px-5">
                {" "}
                Proof that you’ re at least 18 years old{" "}
              </p>{" "}
            </Col>{" "}
            <Col md={8} className="mx-auto pt-3">
              <p>
                If approved, your available credit limit will be reflected in
                your Pave Credit customer dashboard.After your draw has been
                approved, you should receive your funds within 2 business days.{" "}
              </p>{" "}
            </Col>{" "}
          </Row>{" "}
          <Row className="mt-5">
            <Col md={5} className="mx-auto  text-left">
              {" "}
              {/**\              <ol className="main-ol">
                        <li>
                          <p>
                            A verifiable source of income that is direct deposited into
                            your checking account{" "}
                          </p>{" "}
                        </li>{" "}
                        <li>
                          <p>
                            An open checking account that meets our minimum - opened
                            duration requirement{" "}
                          </p>{" "}
                        </li>{" "}
                        <li>
                          <p> Proof that you’re at least 18 years old </p>{" "}
                        </li>{" "}
                      </ol>{" "} */}{" "}
              <h3 className="h3-sub-header"> Initiate a draw </h3>{" "}
              <p>
                To initiate a draw, send a request for the amount that you need,
                and once it’ s approved, we’ ll deliver your funds via direct
                deposit to your bank account.As long as your account is in good
                standing, you can make as many draws as you need up to the total
                amount of your credit limit.Each time you repay a draw, the
                amount available for future draws increases up to your credit
                limit.{" "}
              </p>{" "}
            </Col>{" "}
            <Col md={5} className="mx-auto text-left">
              <h3 className="h3-sub-header"> Making Payments </h3>{" "}
              <p>
                At Pave Credit, we make your payment process quick and
                simple.Once you’ ve received your draw, you’ ll have a minimum
                payment due at the end of each billing cycle as shown within the
                Pave Credit Statement you will receive.You’ ll only pay interest
                on the outstanding balance.{" "}
              </p>{" "}
              <p>
                We align your payments to your pay frequency(weekly, bi -
                weekly, semi - monthly or monthly).Keep in mind, the faster you
                pay off what you owe, the quicker your available line of credit
                refreshes.You can always pay more than your minimum payment, and
                you won’ t be penalized for making payments early.{" "}
              </p>{" "}
            </Col>{" "}
          </Row>{" "}
        </Container>{" "}
      </Container>{" "}
      <Container fluid className=" py-xl-0 payment-options-main">
        <Container
          fluid="xl"
          className="py-xl-6 d-flex flex-column pt-5 payment-options"
        >
          <Row>
            <Col md={{ order: "first" }} className="text-center">
              <h1 className="h1-header"> Payment options </h1>{" "}
              <Col className="px-md-5 py-3 pb-5">
                <h5 className="h5-sub-header">
                  Acceptable payment methods are ACH, RCC and debit card, as
                  well as PayPal, personal checks, cashier’ s checks and money
                  orders.{" "}
                </h5>{" "}
              </Col>{" "}
            </Col>{" "}
          </Row>{" "}
          <Row className="justify-content-center">
            <Col lg={{ order: "first", span: 4 }}>
              <Row>
                <Col className="text-center">
                  <Row className="text-center">
                    <StaticImage
                      className="media-img mx-auto"
                      src="../images/feature_icons/icon_bank.png"
                      alt="Jump Mobile App"
                      placeholder="blurred"
                      width={130}
                    />{" "}
                  </Row>{" "}
                  <Row className="text-center">
                    <p className="mx-auto features-text">
                      {" "}
                      ACH & RCC payments{" "}
                    </p>{" "}
                  </Row>{" "}
                </Col>{" "}
              </Row>{" "}
              <Row>
                <Col className="text-center">
                  <Row className="text-center">
                    <StaticImage
                      className="media-img mx-auto"
                      src="../images/feature_icons/icon_check.png"
                      alt="Jump Mobile App"
                      placeholder="blurred"
                      width={130}
                    />{" "}
                  </Row>{" "}
                  <Row className="text-center">
                    <p className="mx-auto features-text">
                      {" "}
                      Personal & cashier 's checks{" "}
                    </p>{" "}
                  </Row>{" "}
                </Col>{" "}
              </Row>{" "}
              <Row>
                <Col className="text-center">
                  <Row className="text-center">
                    <StaticImage
                      className="media-img mx-auto"
                      src="../images/feature_icons/icon_debit_coming_soon.png"
                      alt="Jump Mobile App"
                      placeholder="blurred"
                      width={130}
                    />{" "}
                  </Row>{" "}
                  <Row className="text-center">
                    <p className="mx-auto m-0 features-text-soon">
                      {" "}
                      COMING SOON.{" "}
                    </p>{" "}
                  </Row>{" "}
                  <Row className="text-center">
                    <small className="mx-auto features-text-soon ">
                      Debit Card payment{" "}
                    </small>{" "}
                  </Row>{" "}
                </Col>{" "}
              </Row>{" "}
            </Col>{" "}
            <Col lg={{ order: "last", span: 4 }}>
              {" "}
              <Row>
                <Col className="text-center">
                  <Row className="text-center">
                    <StaticImage
                      className="media-img mx-auto"
                      src="../images/feature_icons/icon_paypal.png"
                      alt="Jump Mobile App"
                      placeholder="blurred"
                      width={130}
                    />{" "}
                  </Row>{" "}
                  <Row className="text-center">
                    <p className="mx-auto features-text"> PayPal Payments </p>{" "}
                  </Row>{" "}
                </Col>{" "}
              </Row>{" "}
              <Row>
                <Col className="text-center">
                  <Row className="text-center">
                    <StaticImage
                      className="media-img mx-auto"
                      src="../images/feature_icons/icon_money_order.png"
                      alt="Jump Mobile App"
                      placeholder="blurred"
                      width={130}
                    />{" "}
                  </Row>{" "}
                  <Row className="text-center">
                    <p className="mx-auto features-text"> Money order </p>{" "}
                  </Row>{" "}
                </Col>{" "}
              </Row>{" "}
              <Row>
                <Col className="text-center d-none">
                  <Row className="text-center">
                    <StaticImage
                      className="media-img mx-auto"
                      src="../images/feature_icons/icon_cash.png"
                      alt="Jump Mobile App"
                      placeholder="blurred"
                      width={130}
                    />{" "}
                  </Row>{" "}
                  <Row className="text-center">
                    <p className="mx-auto features-text"> Cash </p>{" "}
                  </Row>{" "}
                </Col>{" "}
              </Row>{" "}
            </Col>{" "}
          </Row>{" "}
          <Row>
            <Col className="px-md-5 py-3 pb-5">
              <p className="px-md-5 text-center pb-5">
                If at any time you’ e unable to make your minimum payment, call
                us immediately at <strong> (855) 801 - PAVE </strong> so,
                together, we can work out a solution.We’ ll be happy to work
                with you to accommodate your needs.{" "}
              </p>{" "}
            </Col>{" "}
          </Row>{" "}
        </Container>{" "}
      </Container>{" "}
    </Container>{" "}
    <Footer />
  </>
)

export default HowItWorks
